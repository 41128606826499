import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _omit from 'lodash/omit';
import { Player } from 'cccisd-laravel-flow';
import Loader from 'cccisd-loader';
import flows from './flows.js';
import sizeMe from 'react-sizeme';
import style from './style.css';
import { client as apollo } from 'cccisd-apollo';
import gradeQuery from './gradeQuery.graphql';
import riskSexQuery from './riskSexQuery.graphql';
import _get from 'lodash/get';

const flowKeysToOmit = [
    'flowSettings',
    'isComplete',
    'isStarted',
    'progressBarGroup',
    'showIfOption',
    'surveySettings',
];

class Map extends React.Component {
    scale = 0.8;
    xPadding = 50;
    xBetween = 20 * this.scale;
    yPadding = 50;
    yBetween = 20 * this.scale;
    yDiff = 40 * this.scale;

    static propTypes = {
        flowList: PropTypes.array,
        size: PropTypes.object,
        onFlowStarted: PropTypes.func,
        onFlowComplete: PropTypes.func,
        onAssignmentComplete: PropTypes.func,
        onComplete: PropTypes.func,
        isPreviewMode: PropTypes.bool,
        mediatorProps: PropTypes.object,
    };

    static defaultProps = {
        isPreviewMode: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isMapVisible: true,
            currentFlowHandle: '',
            grade: '',
            showRiskSexQuestions: '',
        };
    }

    componentDidMount = async () => {
        await Promise.all([this.getGrade(), this.getShowRiskSexSetting()]);
        this.setState({ loading: false });
        this.drawLines();
    };

    componentDidUpdate() {
        this.drawLines();
    }

    getGrade = async () => {
        // Check if the survey with the grade question has started
        // set 'gradeSurvey === true' in broom config
        const surveyWithGrade = this.props.flowList.find(survey => {
            return survey.gradeSurvey === true;
        });

        if (surveyWithGrade) {
            // survey with grade question has started
            const response = await apollo.query({
                query: gradeQuery,
                variables: {
                    pawnId: surveyWithGrade.pawnId,
                },
                fetchPolicy: 'network-only',
            });

            // get the assignment progress data that matches the current student language
            const assignmentForCurrentLanguage = response.data.roles.student.assignmentProgressList.find(assignment => {
                return assignment.language === response.data.roles.student.fields.language;
            });

            this.setState({
                grade: _get(assignmentForCurrentLanguage, 'devTags.grade') || '',
            });
        }
    };

    getShowRiskSexSetting = async () => {
        // Check if the teen welcome survey with the risk sex question has started
        // set 'teenBaseSurvey === true' in broom config
        const surveyWithRiskSexQuestion = this.props.flowList.find(survey => {
            return survey.teenBaseSurvey === true;
        });

        if (surveyWithRiskSexQuestion) {
            // survey with risk sex question has started
            const response = await apollo.query({
                query: riskSexQuery,
                variables: {
                    pawnId: surveyWithRiskSexQuestion.pawnId,
                },
                fetchPolicy: 'network-only',
            });

            // get the assignment progress data that matches the current student language
            const assignmentForCurrentLanguage = response.data.roles.student.assignmentProgressList.find(assignment => {
                return assignment.language === response.data.roles.student.fields.language;
            });

            this.setState({
                showRiskSexQuestions: _get(assignmentForCurrentLanguage, 'devTags.RiskSex') || '',
            });
        }
    };

    getWrapperWidth = () => {
        return this.props.size.width;
    };

    drawLines = () => {
        if (!this.state.isMapVisible) {
            return;
        }

        const stat = this.getStat();

        this.canvas.width = this.getWrapperWidth();
        this.canvas.height = stat.wrapperHeight;

        const ctx = this.canvas.getContext('2d');
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const drawLine = (steps, color) => {
            if (steps.length < 2) {
                return;
            }

            ctx.beginPath();
            ctx.strokeStyle = color;
            ctx.lineWidth = 6;
            steps.forEach((step, index) => {
                if (index === 0) {
                    ctx.moveTo(step.box.xMiddle, step.box.yMiddle);
                } else {
                    ctx.lineTo(step.box.xMiddle, step.box.yMiddle);
                }
            });
            ctx.stroke();
        };

        const currentIndex = stat.steps.findIndex(step => step.isCurrent);

        if (currentIndex === -1) {
            drawLine(stat.steps, '#0082ca');
        } else {
            drawLine(stat.steps.slice(0, currentIndex + 1), '#0082ca');
            drawLine(stat.steps.slice(currentIndex, stat.steps.length), '#bbb');
        }
    };

    getStat = () => {
        const { language } = this.props;
        const { forceCurrentStep, completedFlows } = this.props.mediatorProps;
        const wrapperWidth = this.getWrapperWidth();
        let disabled = false;
        let currentStepFound = false;
        let steps = this.props.flowList.map(flow => {
            const mapIcon = flow.surveySettings.mapIcon;
            if (!mapIcon) {
                console.error(`There is no map icon for flow "${flow.handle}"`);
            } else if (!flows[language][mapIcon]) {
                console.error(`There is no map icon "${mapIcon}" for flow "${flow.handle}"`);
            }

            const result = {
                ...flow,
                disabled,
                isCurrent: false,
                box: {
                    ...flows[language][mapIcon],
                    width: flows[language][mapIcon].width * this.scale,
                    height: flows[language][mapIcon].height * this.scale,
                },
            };

            if (!this.props.isPreviewMode) {
                if (!completedFlows.includes(flow.handle) && !currentStepFound && !flow.isComplete) {
                    result.isCurrent = true;
                    currentStepFound = true;
                }
                if (!currentStepFound && forceCurrentStep === flow.mapIcon) {
                    result.isCurrent = true;
                    currentStepFound = true;
                }

                if (result.isCurrent) {
                    disabled = true;
                }
            }

            return result;
        });

        const maxWidth = steps.reduce((result, step) => (result > step.box.width ? result : step.box.width), 0);
        let columnCount =
            Math.floor((wrapperWidth - 2 * this.xPadding + this.xBetween) / (maxWidth + this.xBetween)) || 1;
        const elementsPerColumn = Math.ceil(steps.length / columnCount);
        columnCount = Math.ceil(steps.length / elementsPerColumn); // Adjust columnCount
        const columnWidth = Math.round((wrapperWidth - 2 * this.xPadding) / columnCount);
        const yDiff = columnCount > 1 ? this.yDiff : 0;
        let wrapperHeight = 0;

        for (let i = 0; i < columnCount; i++) {
            let y = this.yPadding;
            for (let j = 0; j < elementsPerColumn; j++) {
                const index = i * elementsPerColumn + j;

                if (steps[index]) {
                    steps[index].box.x = this.xPadding + i * columnWidth + (columnWidth - steps[index].box.width) / 2;
                    steps[index].box.y = y;

                    y += steps[index].box.height + this.yBetween;

                    // adjust wrapperHeight
                    if (wrapperHeight < y + this.yPadding + yDiff) {
                        wrapperHeight = y + this.yPadding + yDiff;
                    }
                }
            }
        }

        // Change direction for the odd columns
        for (let i = 1; i < columnCount; i += 2) {
            for (let j = 0; j < elementsPerColumn; j++) {
                const index = i * elementsPerColumn + j;

                if (steps[index]) {
                    steps[index].box.y = wrapperHeight - steps[index].box.y - steps[index].box.height;
                }
            }
        }

        // Get middle point
        steps.forEach(step => {
            step.box.xMiddle = step.box.x + step.box.width / 2;
            step.box.yMiddle = step.box.y + step.box.height / 2;
        });

        return {
            steps,
            wrapperHeight,
        };
    };

    showFlow = handle => {
        this.setState({ currentFlowHandle: handle, isMapVisible: false });
        const startingFlow = this.props.flowList.find(item => item.handle === handle);
        this.props.onFlowStarted(startingFlow);
    };

    completeCurrentFlow = async () => {
        this.setState({ loading: true });

        const { completedFlows, updateCompletedFlows } = this.props.mediatorProps;

        const stat = this.getStat();
        const currentFlow = stat.steps.find(item => item.handle === this.state.currentFlowHandle);

        const updatedCompletedFlows = [...completedFlows];
        if (!updatedCompletedFlows.includes(currentFlow.handle)) {
            updatedCompletedFlows.push(currentFlow.handle);
        }
        for (const flow of this.props.flowList) {
            if (flow.isComplete && !updatedCompletedFlows.includes(flow.handle)) {
                updatedCompletedFlows.push(flow.handle);
            }
        }
        updateCompletedFlows(updatedCompletedFlows);

        await this.props.onFlowComplete(currentFlow);

        const requiredFlows = this.props.flowList.filter(item => item.isRequired);
        if (requiredFlows.every(item => updatedCompletedFlows.includes(item.handle))) {
            await this.props.onAssignmentComplete();
        }

        if (updatedCompletedFlows.length === this.props.flowList.length) {
            await this.props.onComplete();
        }

        if (currentFlow.gradeSurvey) {
            await this.getGrade();
        }

        if (currentFlow.teenBaseSurvey) {
            await this.getShowRiskSexSetting();
        }

        this.setState({
            isMapVisible: true,
            loading: false,
        });
    };

    getCurrentFlowProps = () => {
        const stat = this.getStat();
        const currentFlow = stat.steps.find(item => item.handle === this.state.currentFlowHandle);
        return {
            ..._omit(currentFlow, flowKeysToOmit),
            enableAudio: currentFlow.useAudio,
            variables: {
                showRiskQuestions: currentFlow.showRiskQuestions,
                grade: this.state.grade,
                showRiskSexQuestions: this.state.showRiskSexQuestions,
            },
            isPreviewMode: this.props.isPreviewMode,
            onComplete: this.completeCurrentFlow,
        };
    };

    render() {
        const stat = this.getStat();

        const content = this.state.isMapVisible ? (
            <div>
                <canvas
                    className={style.canvas}
                    ref={elem => {
                        this.canvas = elem;
                    }}
                />
                <div className={style.map} style={{ width: this.getWrapperWidth(), height: stat.wrapperHeight }} />
                {stat.steps.map(step => (
                    <div
                        key={step.handle}
                        id={`step_${step.mapIcon}_${step.language}`}
                        data-flow-handle={step.handle}
                        className={classnames([
                            'bgca-step',
                            style.step,
                            { [style.disabled]: step.disabled },
                            { [style.current]: step.isCurrent },
                        ])}
                        style={{
                            left: step.box.x,
                            top: step.box.y,
                            width: step.box.width,
                            height: step.box.height,
                            backgroundImage: `url('${step.box.image}')`,
                        }}
                        onClick={step.disabled ? () => {} : () => this.showFlow(step.handle)}
                    />
                ))}
            </div>
        ) : (
            <Player {...this.getCurrentFlowProps()} />
        );

        return (
            <Loader loading={this.state.loading}>
                <div className={style.wrapper}>{content}</div>
            </Loader>
        );
    }
}

export default sizeMe()(Map);
